import { useEffect, useState } from 'react';
import { Image, Button, Card, Form, FloatingLabel, ButtonGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { urlEcommerceBackend } from '../../recursos/configuracion';
import { useUsuarioContexto } from '../../proveedor/usuario';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faImages, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BarraNavegacion } from '../../componentes/BarraNavegacion';

import { conseguirFormato, conseguirBase64, reportar } from '../../recursos/funcion';

export default function EditarProducto() {
  const { proveedorID, producto: productoID } = useParams();
  const cuenta = useUsuarioContexto();
  const navegar = useNavigate();
  const [categorias, setCategorias] = useState([]);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('');

  const [imagen, setImagen] = useState("");
  const [imagenes, setImagenes] = useState([]);
  const [estadoSubir, setEstadoSubir] = useState(true);

  const [producto, setProducto] = useState({});
  const [codigo, setCodigo] = useState(0);

  const [datos, setDatos] = useState(
    {
      formato: '',
      codigo: '',
      file: '',
      id: ''
    }
  );

  const [familias, setFamilias] = useState([]);
  const [subFamilias, setSubFamilias] = useState([]);
  const [marcas, setMarcas] = useState([]);

  
  const [familiaSeleccionada, setFamiliaSeleccionada] = useState('');

  const [subFamiliaSeleccionada, setSubFamiliaSeleccionada] = useState('');

  const [marcaSeleccionada, setMarcaSeleccionada] = useState('');

  

  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      nombre: '',
      costo: '',
      ruta: '',
      marca: '',
      codigo: '',
      stock: '',
      modelo: '',
      descripcion: ''
    }
  });


  useEffect(() => {

    // console.log('cuenta', cuenta);

    async function fetchCategorias() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/categorias`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });
        if (response.data && response.data.arrayJson) {
          setCategorias(response.data.arrayJson);
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }
    fetchCategorias();

    async function fetchFamilias() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/familias`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          // console.log('Familias', response.data);
          setFamilias(response.data.arrayJson);
        } else {
          console.log('No se econtraron familias');
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchFamilias();


    async function fetchSubFamilias() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/subFamilias`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          // console.log('SubFamilias', response.data);
          setSubFamilias(response.data.arrayJson);
        } else {
          console.log('No econtraron subfamilias');
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchSubFamilias();

    async function fetchMarcas() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/marcasEcont`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        if (response.data && response.data.arrayJson) {
          // console.log('Marcas Econt', response.data);
          setMarcas(response.data.arrayJson);
        } else {
          console.log('No encontraron marcas');
        }
      } catch (error) {
        console.error('Error al traer las categorías', error);
      }
    }

    fetchMarcas();

    async function fetchProductoEcont(){
      // Obtener producto del econt y setear 
      let response = await Axios.get(`${urlEcommerceBackend}/productos/consulta/${productoID}`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });

      console.log('ProductoEcont', response.data.arrayJson[0]);
      setValue('codigoBarra', response.data.arrayJson[0].codigodebarra);
      setFamiliaSeleccionada(response.data.arrayJson[0].familia); 
      setSubFamiliaSeleccionada(response.data.arrayJson[0].subfamilia); 
      setMarcaSeleccionada(response.data.arrayJson[0].marca);
    }

    fetchProductoEcont();

  }, [cuenta.token]);


  useEffect(() => {
    async function fetchProducto() {
      try {
        let response = await Axios.get(`${urlEcommerceBackend}/proveedores/registro/${proveedorID}/${productoID}`, {
          headers: {
            authorization: `Bearer ${cuenta.token}`
          }
        });

        console.log('ProductoEcommerce', response.data.objetoJson);
        // console.log('response.data.objetoJson.imagenes', response.data.objetoJson.imagenes);

        setImagenes(response.data.objetoJson.imagenes);

        setProducto(response.data.objetoJson);

        const producto = response.data.objetoJson;

        reset({
          nombre: producto.nombre,
          costo: producto.costo,
          ruta: producto.categoria,
          marca: producto.marca.nombre,
          codigo: producto.codigo,
          stock: producto.cantidad,
          modelo: producto.modelo,
          descripcion: producto.descripcion
        });

        // console.log('producto.categorias[0].ruta', producto.categorias[0].ruta);

        setCategoriaSeleccionada(producto.categorias[0].ruta);


      } catch (error) {
        console.error('Error al cargar el producto', error);
      }
    }
    if (productoID) {
      fetchProducto();
    }
  }, [productoID, cuenta.token, reset]);


  useEffect(() => {
    reset((prevValues) => ({
      ...prevValues,
      ruta: categoriaSeleccionada,
    }));
  }, [categoriaSeleccionada, reset]);

  async function recuperar() {
    try {
      let response = await Axios.get(`${urlEcommerceBackend}/proveedores/registro/${proveedorID}/${productoID}`, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });

      console.log('response.data', response.data.objetoJson);
      console.log('response.data.objetoJson.imagenes', response.data.objetoJson.imagenes);

      setImagenes(response.data.objetoJson.imagenes);

      setProducto(response.data.objetoJson);
    } catch (error) {
      await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "recuperar", `${error.toString()}`, {
        error
      }, 1);
    }
  }



  const onSubmit = async (data) => {

    console.log('familiaSeleccionada');
    console.log(familiaSeleccionada);
    console.log('subFamiliaSeleccionada');
    console.log(subFamiliaSeleccionada);
    console.log('marcaSeleccionada');
    console.log(marcaSeleccionada);


    const parametros = {
      codigoNuevo: data.codigo,
      nombre: data.nombre,
      descripcion: data.descripcion,
      costo: data.costo,
      categoria: categoriaSeleccionada,
      marca: data.marca,
      cantidad: data.stock,
      modelo: data.modelo,
      familia: familiaSeleccionada,
      subfamilia: subFamiliaSeleccionada,
      marcaEcont: marcaSeleccionada,
      codigoBarra: data.codigoBarra
    };

    try {
      let respuesta = await Axios.put(`${urlEcommerceBackend}/proveedores/${proveedorID}/productos/${productoID}`, parametros, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      console.log('Producto actualizado con éxito:', respuesta.data);
      navegar(`/proveedores/${proveedorID}/1/10`);
    } catch (error) {
      console.error('Error al actualizar el producto', error);
    }
  };

  function elegirArchivo() {
    let informacion = document.getElementById('formImagen').files[0];
    let reader = new FileReader();
    reader.readAsDataURL(informacion);
    reader.onloadend = () => {
      setImagen(reader.result);
      setEstadoSubir(false);
      setDatos(
        {
          formato: conseguirFormato(informacion.name),
          codigo: producto.codigo,
          file: conseguirBase64(reader.result),
          id: producto._id
        }
      );
    };
  }

  async function subir() {
    if (document.getElementById('formImagen').value === '') {
      Swal.fire(
        'Subida de imágenes',
        'No se ha seleccionado imagen',
        'info'
      )
    } else {
      setEstadoSubir(true);
      try {
        console.log('datos', datos);

        let respuesta = await Axios.post(`${urlEcommerceBackend}/almacen/actualizarProductoProveedor`, datos, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${cuenta.token}`
          }
        });
        document.getElementById('formImagen').value = '';
        setImagen("https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/logos/store/lcng/600.png");
        recuperar();
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })

        Toast.fire({
          icon: 'success',
          title: respuesta.data.descripcion
        })
      } catch (error) {
        await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "subir", `${error.toString()}`, {
          error
        }, 1);
      }
    }
  }

  function eliminar(variante, formato) {
    Swal.fire({
      icon: 'question',
      title: 'Esta seguro/a de eliminar esta imagen?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let parametros = {
            id: producto._id,
            codigo: producto.codigo,
            formato: formato,
            variante: variante
          };
          let respuesta = await Axios.delete(`${urlEcommerceBackend}/almacen`, {
            data: parametros,
            headers: {
              authorization: `Bearer ${cuenta.token}`
            }
          });
          recuperar();
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          Toast.fire({
            icon: 'success',
            title: respuesta.data.descripcion
          })
        } catch (error) {
          await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "eliminar", `${error.toString()}`, {
            error
          }, 1);
        }
      }
    });
  }

  async function mover(lado, codigo, variante) {
    try {
      let parametros = {
        lado: lado,
        codigo: codigo,
        variante: variante
      }
      let respuesta = await Axios.put(`${urlEcommerceBackend}/almacen`, parametros, {
        headers: {
          authorization: `Bearer ${cuenta.token}`
        }
      });
      recuperar();
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      Toast.fire({
        icon: 'success',
        title: respuesta.data.descripcion
      })
    } catch (error) {
      await reportar(`${cuenta.nombre} ${cuenta.apellido}`, "Ecommerce Admin", "Frontend", "galeria.jsx", "mover", `${error.toString()}`, {
        error
      }, 1);
    }
  }


  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div className='container mt-4'>
        <div className='row'>
          {/* Formulario de edición de producto */}
          <div className='col-lg-5 mx-auto'>
            <Card className='p-4'>
              <h1 className='text-center'>Editar Producto</h1>
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* Campo Código */}
                <Form.Group className='mb-3'>
                  <Form.Label>Código</Form.Label>
                  <Form.Control
                    type='text'
                    {...register('codigo', { required: 'El código es obligatorio' })}
                    isInvalid={!!errors.codigo}
                    disabled={true}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.codigo?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Campo Nombre */}
                <Form.Group className='mb-3'>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type='text'
                    {...register('nombre', { required: 'El nombre es obligatorio' })}
                    isInvalid={!!errors.nombre}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.nombre?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Campo Descripción */}
                <Form.Group className='mb-3'>
                  <Form.Label>Descripción</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    {...register('descripcion')}
                  />
                </Form.Group>

                {/* Campo Costo */}
                <Form.Group className='mb-3'>
                  <Form.Label>Precio de costo</Form.Label>
                  <Form.Control
                    type='number'
                    step='0.01'
                    {...register('costo', {
                      required: 'El costo es obligatorio',
                      min: { value: 0.01, message: 'El costo debe ser mayor que 0' }
                    })}
                    isInvalid={!!errors.costo}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.costo?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Campo Stock */}
                <Form.Group className='mb-3'>
                  <Form.Label>Stock</Form.Label>
                  <Form.Control
                    type='number'
                    {...register('stock', {
                      required: 'El stock es obligatorio',
                      min: { value: 0, message: 'El stock debe ser mayor o igual a 0' }
                    })}
                    isInvalid={!!errors.stock}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.stock?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Campo Modelo */}
                <Form.Group className='mb-3'>
                  <Form.Label>Modelo</Form.Label>
                  <Form.Control
                    type='text'
                    {...register('modelo', { required: 'El modelo es obligatorio' })}
                    isInvalid={!!errors.modelo}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.modelo?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Campo Categoría */}
                <FloatingLabel controlId='selectCategoria' label='Categoría' className='mb-3'>
                  <Form.Select
                    aria-label='Categoría'
                    value={categoriaSeleccionada || ''}
                    onChange={(e) => setCategoriaSeleccionada(e.target.value)}
                  >
                    <option value=''>Seleccionar categoría</option>
                    {categorias.map((categoria) => (
                      <option key={categoria.ruta} value={categoria.ruta}>
                        {categoria.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>

                {/* Campo Familia */}
                <FloatingLabel controlId='selectFamilia' label='Familia' className='mb-3'>
                  <Form.Select
                    aria-label='Familia'
                    value={familiaSeleccionada || ''}
                    onChange={(e) => setFamiliaSeleccionada(e.target.value)}
                  >
                    <option value=''>Seleccionar familia</option>
                    {familias.map((familia) => (
                      <option key={familia.codigo} value={familia.codigo}>
                        {familia.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>

                {/* Campo SubFamilia */}
                <FloatingLabel controlId='selectSubFamilia' label='SubFamilia' className='mb-3'>
                  <Form.Select
                    aria-label='SubFamilia'
                    value={subFamiliaSeleccionada || ''}
                    onChange={(e) => setSubFamiliaSeleccionada(e.target.value)}
                  >
                    <option value=''>Seleccionar subfamilia</option>
                    {subFamilias.map((subFamilia) => (
                      <option key={subFamilia.codigo} value={subFamilia.codigo}>
                        {subFamilia.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>

                {/* Campo Marca */}
                <FloatingLabel controlId='selectMarca' label='Marca' className='mb-3'>
                  <Form.Select
                    aria-label='Marca'
                    value={marcaSeleccionada || ''}
                    onChange={(e) => setMarcaSeleccionada(e.target.value)}
                  >
                    <option value=''>Seleccionar marca</option>
                    {marcas.map((marca) => (
                      <option key={marca.codigo} value={marca.codigo}>
                        {marca.nombre}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>

                {/* Campo Código de barras */}
                <Form.Group className='mb-3'>
                  <Form.Label>Código de barras</Form.Label>
                  <Form.Control
                    type='text'
                    {...register('codigoBarra')}
                    isInvalid={!!errors.codigoBarra}
                    disabled={true}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.codigoBarra?.message}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* Botones separados */}
                <div className='d-flex justify-content-between'>
                  <Button variant='success' onClick={() => navegar(`/proveedores/${proveedorID}/1/10`)}>
                    Volver
                  </Button>
                  <Button type='submit'>Actualizar</Button>
                </div>
              </Form>
            </Card>
          </div>

          {/* Formulario de carga de imagen y galería */}
          <div className='col-lg-5 mx-auto'>
            <div className="container-fluid mt-3 mb-3">
              <div className="row">
                {/* Formulario de carga de imagen */}
                <div className='col-md-12 mb-3'>
                  <h1><FontAwesomeIcon icon={faImages} /> Galería</h1>
                  <Form.Group controlId="formImagen">
                    <Form.Control type="file" size="lg" onChange={elegirArchivo} accept='image/*' />
                  </Form.Group>
                  <div className="d-grid gap-2 mt-3 mb-3">
                    <Button variant="outline-success" size="lg" onClick={subir} disabled={estadoSubir}>
                      <FontAwesomeIcon icon={faUpload} /> Subir
                    </Button>
                  </div>
                </div>

                {/* Galería de imágenes */}
                <div className="col-md-12">
                  <Card border="secondary">
                    <Card.Body>
                      <div className='row'>
                        {imagenes.map((imagen, key) => (
                          <div className='col-md-12 mb-2' key={key}>
                            <Card border="secondary">
                              <Card.Img variant="top" src={`https://csdigitalizacion.nyc3.cdn.digitaloceanspaces.com/ecommerce/store/${imagen.url['600']}`} />
                              <Card.Footer>
                                <div className="d-grid gap-2">
                                  <ButtonGroup size="lg" className="mb-2">
                                    <Button variant="outline-primary" onClick={() => mover(0, productoID, imagen.variante, imagen.formato)}>
                                      <FontAwesomeIcon icon={faChevronLeft} />
                                    </Button>
                                    <Button variant="outline-primary" onClick={() => mover(1, productoID, imagen.variante, imagen.formato)}>
                                      <FontAwesomeIcon icon={faChevronRight} />
                                    </Button>
                                  </ButtonGroup>
                                  <Button variant="outline-danger" onClick={() => eliminar(imagen.variante, imagen.formato)} size="lg">
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                </div>
                              </Card.Footer>
                            </Card>
                            <br></br>
                          </div>
                        ))}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  );
}
